<template>
  <div class="vue-modal-wrapper" @click="outsideHandler">
    <div class="vue-modal-container">
      <div class="vue-modal-title">
        <slot name="title"></slot>
        <span
          class="vue-modal-close-btn"
          :class="{ 'default-close-button': defaultCloseButton }"
          @click="closeModalHandler"
        >
          <img class="close-img" src="/assets/images/close.png" alt="Закрыть" />
        </span>
      </div>
      <div class="vue-modal-body">
        <slot name="body"></slot>
      </div>
      <div class="vue-modal-footer">
        <slot name="footer"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TheModal",
  props: {
    defaultCloseButton: {
      type: Boolean,
      default: false
    },
    closeModalHandler: {
      type: Function,
      default: () => {}
    },
    outsideModalHandler: {
      type: Function,
      default: () => {}
    }
  },
  methods: {
    outsideHandler(event) {
      if (event.srcElement.className.includes("modal-wrapper")) {
        this.outsideModalHandler();
      }
    }
  }
};
</script>

<style scoped lang="scss" src="./TheModal.scss"></style>
