import api from "./index";

let lastRequestTime = 0;
let lastAxiosResponse;
let waitingForServerResponse = false;
const resolveRejects = [];
const REFRESH_TOKEN_AFTER_MILLISECS = 5 * 60 * 1000; // 5 minutes
/**
 * new Date().getTime()
 * количеством миллисекунд, прошедших с 1 января 1970 года 00:00:00 по UTC
 * @returns {boolean}
 */
const needToRefreshApiToken = () =>
    new Date().getTime() - lastRequestTime > REFRESH_TOKEN_AFTER_MILLISECS && !waitingForServerResponse;
const resolveAllPromises = payload => {
    resolveRejects.forEach(promise => promise.resolve(payload));
    resolveRejects.length = 0;
};
const rejectAllPromises = e => {
    resolveRejects.forEach(promise => promise.reject(e));
    resolveRejects.length = 0;
};

/**
 * Proxy wrapper on the API session token request
 * Executes real API http request once in REFRESH_TOKEN_AFTER_MILLISECS
 * @returns {Promise<AxiosResponse>}
 */
const getToken = () => {
    return new Promise((resolve, reject) => {
        resolveRejects.push({ resolve, reject });
        if (needToRefreshApiToken()) {
            waitingForServerResponse = true;
            api()
                .get("/session", { timeout: 5000 })
                .then(axiosResponse => {
                    lastAxiosResponse = axiosResponse;
                    resolveAllPromises(axiosResponse);
                    lastRequestTime = new Date().getTime();
                    waitingForServerResponse = false;
                })
                .catch(e => {
                    rejectAllPromises(e);
                    waitingForServerResponse = false;
                });
        } else {
            resolveAllPromises(lastAxiosResponse);
        }
    });
};

export { getToken };
