import api from "./index";
import axios from "axios";
import { getToken } from "@/services/sessionService";

const defaultTimeoutMsec = 10000;

const getAnalisisReults = () => {
    const instance = api();
    const cancelTokenSource = axios.CancelToken.source();
    const cancelToken = cancelTokenSource.token;

    return {
        instance,
        cancelToken,
        cancelTokenSource,
        execute: function(data) {
            return getToken().then(() =>
                this.instance.post("/get-results", data, {
                    cancelToken: this.cancelToken,
                    timeout: defaultTimeoutMsec
                })
            );

            // return this.instance.post( '/getAnalisisResults', data, { cancelToken: this.cancelToken })
        }
    };
};

const getAnalisisVerificationResults = () => {
    const instance = api();
    const cancelTokenSource = axios.CancelToken.source();
    const cancelToken = cancelTokenSource.token;

    return {
        instance,
        cancelToken,
        cancelTokenSource,
        execute: function(data) {
            return getToken().then(() =>
                this.instance.post("/verify-results", data, {
                    cancelToken: this.cancelToken,
                    timeout: defaultTimeoutMsec
                })
            );
        }
    };
};

export { getAnalisisReults, getAnalisisVerificationResults };
