<template>
  <div class="app-view">
    <transition name="slide-fade-left">
      <div v-if="!online" class="offline-message">Не в сети</div>
    </transition>
    <transition name="slide-fade-right">
      <div v-if="REQUEST_TIMEOUT" class="timer-message">
        {{ new Date(currentTime * 1000).toISOString().substr(11, 8) }}
      </div>
    </transition>
    <router-view />
    <!-- <transition name="fade" mode="out-in" v-if="windowWidth < 873">
      <router-view />
    </transition>
    <span v-else>
      <router-view />
    </span> -->
    <transition name="fade">
      <div class="modal-cackground" v-if="SESSION_ERROR"></div>
    </transition>
    <transition name="size">
      <div class="vue-the-modal-wrapper" v-if="SESSION_ERROR">
        <vue-the-modal
          class="vue-the-modal"
          :defaultCloseButton="true"
          :closeModalHandler="closeErrorModal"
          :outsideModalHandler="closeErrorModal"
        >
          <template v-slot:title>
            <h2 class="vue-the-modal-title">{{ SESSION_ERROR.title }}</h2>
          </template>
          <template v-slot:body>
            <div class="vue-the-modal-body">
              <p class="vue-the-modal-message">
                {{ SESSION_ERROR.message }}
              </p>
              <span class="vue-the-modal-btns">
                <button class="vue-the-modal-ok-btn" @click="closeErrorModal">ОК</button>
              </span>
            </div>
          </template>
        </vue-the-modal>
      </div>
    </transition>
  </div>
</template>

<script>
import TheModal from "@/components/TheModal/TheModal";

import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  name: "AppView",
  data() {
    return {
      windowWidth: 0,
      currentTime: 0,
      timer: null
    };
  },
  computed: {
    ...mapGetters({
      online: "ONLINE",
      SERVICE_WORKER: "SERVICE_WORKER_MODULE/SERVICE_WORKER",
      REQUEST_TIMEOUT: "ANALISIS_RESULTS_MODULE/REQUEST_TIMEOUT",
      SESSION_ERROR: "SESSION_ERROR"
    })
  },
  created() {
    // this.SET_ONLINE_STATUS( window.navigator.onLine )
    // this.INIT_SW_MESSAGE_EVENT_HANDLER()
    // window.addEventListener( 'online', () => {
    //   this.SET_ONLINE_STATUS( true )
    // })
    // window.addEventListener( 'offline', () => {
    //   this.SET_ONLINE_STATUS( false )
    // })
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.getWindowWidth);

      this.getWindowWidth();
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.getWindowWidth);
  },
  methods: {
    ...mapMutations({
      SET_REQUESTS_TIMEOUT: "ANALISIS_RESULTS_MODULE/SET_REQUESTS_TIMEOUT",
      SET_SESSION_ERROR: "SET_SESSION_ERROR"
    }),
    ...mapActions({
      TEST_POST: "TEST_POST",
      SET_ONLINE_STATUS: "SET_ONLINE_STATUS",
      INIT_SW_MESSAGE_EVENT_HANDLER: "SERVICE_WORKER_MODULE/INIT_SW_MESSAGE_EVENT_HANDLER",
      RESET_ERROR: "ANALISIS_RESULTS_MODULE/RESET_ERROR"
    }),
    closeErrorModal() {
      this.SET_SESSION_ERROR(null);
    },
    getWindowWidth() {
      this.windowWidth = document.documentElement.clientWidth;
    },
    startTimer() {
      this.timer = setInterval(() => {
        console.log("tick");
        this.currentTime--;
      }, 1000);
      setTimeout(() => {
        clearInterval(this.timer);
      }, this.currentTime * 1100);
    },
    stopTimer() {
      clearTimeout(this.timer);
      this.SET_REQUESTS_TIMEOUT(null);
      this.RESET_ERROR();
    }
  },
  watch: {
    REQUEST_TIMEOUT() {
      this.currentTime = this.REQUEST_TIMEOUT;

      this.startTimer();
    },
    currentTime(time) {
      if (time === 0) {
        this.stopTimer();
      }
    }
  },
  components: {
    "vue-the-modal": TheModal
  }
};
</script>

<style scoped lang="scss" src="./AppView.scss"></style>
