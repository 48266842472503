<template>
  <label class="input-component-label">
    <div class="help" v-if="helpHandler" @click.prevent="helpHandler">
      <img src="/assets/images/info.png" alt="" />
    </div>
    <div class="check-mark" v-if="checkMarkHandler">
      <img src="/assets/images/check-mark.png" alt="" />
    </div>
    <div class="check-mark-phone" v-if="checkMarkPhoneHandler">
      <img src="/assets/images/check-mark.png" alt="" />
    </div>
    <input
      type="text"
      class="input-component-input"
      v-model="value"
      :class="{
        filled: !!value,
        error: hasError,
        prefixed: prefixValue
      }"
      @input="inputHandler"
      @change="changedHandler"
      pattern
      @paste="onpaste"
      :ref="refName"
      @focusout="focusout"
      @focus="startfocus"
      :autofocus="autofocus"
      :tabindex="tabindex"
    />
    <span class="input-component-label-text" :class="{ prefixed: prefixValue }">{{ label }}</span>
    <input
      type="text"
      v-if="prefixValue"
      v-model="prefixValue"
      class="input-component-input input-component-prefix"
      disabled
    />
  </label>
</template>

<script>
export default {
  name: "InputComponent",
  props: {
    tabindex: {
      type: Number,
      default: null
    },
    autofocus: {
      type: Boolean,
      default: false
    },
    refName: {
      type: String,
      default: null
    },
    label: {
      type: String,
      required: true
    },
    inputHandler: {
      type: Function,
      default: () => {}
    },
    changedHandler: {
      type: Function,
      default: () => {}
    },
    prefixValue: {
      type: String,
      default: ""
    },
    hasError: {
      type: Boolean,
      default: true
    },
    helpHandler: {
      type: Function,
      default: null
    },
    checkMarkHandler: {
      type: Boolean,
      default: false
    },
    checkMarkPhoneHandler: {
      type: Boolean,
      default: false
    },
    onpaste: {
      type: Function,
      default: () => {}
    },
    startfocus: {
      type: Function,
      default: () => {}
    },
    focusout: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      value: "",
      ref: null
    };
  },
  mounted() {
    this.ref = this.refName;
  }
};
</script>

<style scoped lang="scss" src="./InputComponent.scss"></style>
