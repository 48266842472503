<template>
  <div class="main-view-wrapper">
    <vue-the-header class="vue-the-header" />
    <vue-the-request-result-form class="vue-the-request-result-form" />
    <vue-the-footer class="vue-the-footer" />
  </div>
</template>

<script>
import TheHeader from "@/components/TheHeader/TheHeader";
import TheRequestResultForm from "@/components/TheRequestResultForm/TheRequestResultForm";
import TheFooter from "@/components/TheFooter/TheFooter";

export default {
  name: "MainView",
  components: {
    "vue-the-header": TheHeader,
    "vue-the-request-result-form": TheRequestResultForm,
    "vue-the-footer": TheFooter
  }
};
</script>

<style scoped lang="scss" src="./MainView.scss"></style>
