<template>
  <div class="main-view-wrapper">
    <span class="main-view-wrapper-header">
      <vue-the-header class="vue-the-header" />
      <vue-the-navbar />
    </span>
    <span class="main-view-wrapper-body">
      <vue-the-result-table class="vue-the-result-table" />
    </span>
    <vue-the-footer class="vue-the-footer" />
  </div>
</template>

<script>
import TheHeader from "@/components/TheHeader/TheHeader";
import TheFooter from "@/components/TheFooter/TheFooter";
import TheResultTable from "@/components/TheResultTable/TheResultTable";
import TheNavbar from "@/components/TheNavbar/TheNavbar";

export default {
  name: "ResultView",
  components: {
    "vue-the-header": TheHeader,
    "vue-the-footer": TheFooter,
    "vue-the-result-table": TheResultTable,
    "vue-the-navbar": TheNavbar
  }
};
</script>

<style scoped lang="scss" src="./ResultView.scss"></style>
