import { loadScript } from "../lib/loadScript";

const BASE_URL = process.env.BASE_URL;
const LOCAL_PATHS = {
    PDF_OBJECT: BASE_URL + "assets/pdfobject.min.js"
};
// const loadedPDFScripts = new Map();

const localPDFScript = loadScript(LOCAL_PATHS.PDF_OBJECT);

// function createPDFEmbed(pdfPath, viewId){
//     const wrapper = document.querySelector('.pdf-wrapper')
//     const viewer = document.createElement('div')
//     viewer.id = viewId
//     if(!loadedPDFScripts.has(viewId)){
//         wrapper.appendChild(viewer)
//     }
//     loadedPDFScripts.set(viewId, pdfPath)
// }

function createPDFViewer() {
    return localPDFScript();
    // .then(()=>{
    //     // createPDFEmbed(pdfPath, viewId)
    // })
}

export { createPDFViewer };
