import ServiceWorkerController from "@/sw/ServiceWorkerController";
import Vue from "vue";
import App from "./App.vue";
import "./assets/scss/global.scss";
import router from "./router";
import store from "./store";
import { VueReCaptcha } from "vue-recaptcha-v3";

Vue.config.devtools = false;
Vue.config.productionTip = process.env.NODE_ENV === "production";

Vue.use(VueReCaptcha, {
    siteKey: process.env.VUE_APP_SITE_KEY,
    loaderOptions: {
        explicitRenderParameters: {
            badge: "bottomleft"
        }
    }
});

// if ( 'serviceWorker' in navigator ) {
//     const serviceWorkerController = new ServiceWorkerController( '/serviceworker.js' )

//     window.addEventListener( 'load', async () => {
//         await serviceWorkerController.registerServiceWorker()
//         await serviceWorkerController.registerBackgroundSync( 'service-worker-ready' )
//         await serviceWorkerController.registerNetworkStatusEvents()

//         mountVueApp()
//     })
// } else {
//     mountVueApp()
// }

if ("serviceWorker" in navigator) {
    const serviceWorkerController = new ServiceWorkerController("/serviceworker.js");

    window.addEventListener("load", async () => {
        await serviceWorkerController.unregisterServiceWorker();

        mountVueApp();
    });
} else {
    mountVueApp();
}

function mountVueApp() {
    setTimeout(() => {
        new Vue({
            store,
            router,
            render: h => h(App)
        }).$mount("#app");
    }, 300);
}
