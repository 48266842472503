<template>
  <header class="header-main-window" v-if="$route.name === 'MainViewRoute'">
    <img @click="reroteToMain" src="/assets/images/openlab_logo_.svg" alt="" class="image" />
    <!--<div @click="reroteToMain" class="header-title-wrapper">
      <h1 class="title">открытая<br>лаборатория</h1>
    </div>-->
  </header>
  <header class="header-result-window" v-else>
    <div class="logo-block">
      <img @click="reroteToMain" src="/assets/images/openlab_logo_.svg" alt="" class="image" />
      <!--<div @click="reroteToMain" class="header-title-wrapper">
        <h1 class="title">открытая<br>лаборатория</h1>
      </div>-->
    </div>
    <div class="contact-block">
      <p class="name-item">ООО «Открытая лаборатория»</p>
      <div class="address-block">
        <p class="address-item">ул. Карла Маркса, 15/1</p>
        <a href="tel:83822901415" class="phone-item">8 (3822) 901-415</a>
        <a href="http://oplab.ru" class="web-site-item" rel="nofollow noopener">oplab.ru</a>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: "TheHeader",
  methods: {
    reroteToMain() {
      if (this.$router.currentRoute.name === "MainViewRoute") {
        this.$router.go({ name: "MainViewRoute" });
      } else {
        this.$router.push({ name: "MainViewRoute" });
      }
    }
  }
};
</script>

<style scoped lang="scss" src="./TheHeader.scss"></style>
