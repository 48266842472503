<template>
  <section class="request-result-form-wrapper">
    <form class="request-result-form" @keyup="tooltipEvent" @keydown="checkSubmitAccess">
      <h1 class="form-title">Результаты анализов</h1>
      <div class="form-group">
        <button
          class="submit-button"
          :tabindex="4"
          @click.prevent="submit"
          :disabled="!!REQUEST_TIMEOUT || !(isShowPhoneCheckMark && (isShowOrderCheckMark || isShowBarcodeCheckMark))"
        >
          Запросить
        </button>
        <span class="error-text">{{ errorText }}</span>
        <div class="tab-content">
          <div class="vue-input-component-wrapper">
            <vue-input-component
              id="phoneNumberComponent"
              ref="phoneNumberComponent"
              name="phoneNumberComponent"
              class="form-group-item"
              label="Телефон"
              :inputHandler="phoneInputHandler"
              :prefixValue="phoneNumberPrefix"
              :hasError="phoneNumberError"
              :changedHandler="changedPhoneNumberHandler"
              :tabindex="1"
              :startfocus="focusstartEvent"
              :focusout="focusoutEvent"
              :checkMarkPhoneHandler="isShowPhoneCheckMark"
            />
          </div>
          <div class="tabs_block" v-show="currentTab">
            <div class="vue-input-component-wrapper">
              <vue-input-component
                ref="orderNumberComponent"
                name="orderNumberComponent"
                class="form-group-item middle-item"
                label="Номер заказа"
                :inputHandler="formatOrderNumber"
                :hasError="orderNumberError"
                :changedHandler="changedOrderNumberHandler"
                :helpHandler="showOrderHelpModal"
                :focusout="closePhoneNumberTooltip"
                :tabindex="2"
                :checkMarkHandler="isShowOrderCheckMark"
              />
              <div
                class="vue-input-component-tooltip"
                :class="{
                  showed: tooLongOrderNumberTooltipShowed
                }"
              >
                <p class="vue-input-component-tooltip-text">
                  Похоже, вы вводите штрих-код вместо номера заказа.
                  <br />
                  Переключиться на ввод штрих-кода?
                  <br />
                  <span class="vue-input-component-tooltip-btn-wrapper">
                    <button class="vue-input-component-tooltip-btn close-button" @click.prevent="noTooltipCallback">
                      Нет
                    </button>
                    <button class="vue-input-component-tooltip-btn" @click.prevent="changeFocusToBarcodeNumber">
                      Да
                    </button>
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div class="tabs_block" v-show="!currentTab">
            <div class="vue-input-component-wrapper">
              <vue-input-component
                ref="barcodeNumberComponent"
                name="barcodeNumberComponent"
                class="form-group-item middle-item"
                label="Номер штрих-кода"
                :inputHandler="formatBarcodeNumber"
                :hasError="barcodeNumberError"
                :changedHandler="changedBarcodeNumberHandler"
                :helpHandler="showBarcodeHelpModal"
                refName="barcodeNumberComponentInput"
                :tabindex="3"
                :checkMarkHandler="isShowBarcodeCheckMark"
              />
            </div>
          </div>
        </div>
        <ul class="tabs">
          <li @click="currentTab = !currentTab" class="tabs-link active" id="order">
            <a class="nav-link" @click="activeLink" data-id="order">по номеру заказа</a>
          </li>
          <li @click="currentTab = !currentTab" class="tabs-link" id="barcode">
            <a class="nav-link" @click="activeLink" data-id="barcode">по штрих-коду</a>
            <transition name="list" v-if="blinkActiveTab">
              <div class="box"></div>
            </transition>
          </li>
        </ul>
      </div>
    </form>

    <transition name="fade">
      <div
        class="modal-cackground"
        v-if="ANALISIS_RESULTS_REQUESTING || ERROR || orderHelpModalShowed || barcodeHelpModalShowed"
      ></div>
    </transition>

    <transition name="size">
      <div class="vue-the-modal-wrapper" v-if="ANALISIS_RESULTS_REQUESTING">
        <vue-the-modal class="vue-the-modal">
          <template v-slot:title>
            <h2 class="vue-the-modal-title">Результат</h2>
          </template>
          <template v-slot:body>
            <div class="vue-the-modal-body">
              <p class="vue-the-modal-message">
                Ваш запрос принят в обработку.
                <br />
                Ожидайте результатов.
              </p>
              <div class="vue-the-loader-wrapper">
                <vue-the-loader class="vue-the-loader" />
              </div>
              <span class="vue-the-modal-btns">
                <button class="vue-the-modal-ok-btn" @click="CANCEL_ANALISIS_RESULTS_REQUESTING">Отмена</button>
              </span>
            </div>
          </template>
        </vue-the-modal>
      </div>
    </transition>

    <transition name="size">
      <div class="vue-the-modal-wrapper" v-if="ERROR">
        <vue-the-modal
          class="vue-the-modal"
          :defaultCloseButton="true"
          :closeModalHandler="RESET_ERROR"
          :outsideModalHandler="RESET_ERROR"
        >
          <template v-slot:title>
            <h2 class="vue-the-modal-title">{{ ERROR.title }}</h2>
          </template>
          <template v-slot:body>
            <div class="vue-the-modal-body">
              <p class="vue-the-modal-message">
                {{ ERROR.message }}
              </p>
              <span class="vue-the-modal-btns">
                <button class="vue-the-modal-ok-btn" @click="RESET_ERROR">ОК</button>
              </span>
            </div>
          </template>
        </vue-the-modal>
      </div>
    </transition>

    <transition name="size">
      <div class="vue-the-modal-wrapper" v-if="orderHelpModalShowed">
        <vue-the-modal
          class="vue-the-modal"
          :defaultCloseButton="true"
          :closeModalHandler="closeOrderHelpModal"
          :outsideModalHandler="closeOrderHelpModal"
        >
          <template v-slot:title>
            <h2 class="vue-the-modal-title">Где найти номер договора?</h2>
          </template>
          <template v-slot:body>
            <div class="vue-the-modal-body">
              <img src="assets/images/example-order-number.png" alt="Номер заказа" />
              <p class="vue-the-modal-description">
                Важно! Если номер договора состоит из 5-ти цифр (пример ниже), при вводе добавьте 0 в начало номера
              </p>
              <img class="big-example" src="assets/images/example-order-5-number.jpg" alt="Номер заказа 2" />
              <span class="vue-the-modal-btns">
                <button class="vue-the-modal-ok-btn" @click="closeOrderHelpModal">Закрыть</button>
              </span>
            </div>
          </template>
        </vue-the-modal>
      </div>
    </transition>

    <transition name="size">
      <div class="vue-the-modal-wrapper" v-if="barcodeHelpModalShowed">
        <vue-the-modal
          class="vue-the-modal"
          :defaultCloseButton="true"
          :closeModalHandler="closeBarcodeHelpModal"
          :outsideModalHandler="closeBarcodeHelpModal"
        >
          <template v-slot:title>
            <h2 class="vue-the-modal-title">Номер штрих-кода</h2>
          </template>
          <template v-slot:body>
            <div class="vue-the-modal-body">
              <img src="assets/images/example-barcode-number.jpg" alt="Номер штрих-кода" />
              <span class="vue-the-modal-btns">
                <button class="vue-the-modal-ok-btn" @click="closeBarcodeHelpModal">Закрыть</button>
              </span>
            </div>
          </template>
        </vue-the-modal>
      </div>
    </transition>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import InputComponent from "@/components/InputComponent/InputComponent";
import TheModal from "@/components/TheModal/TheModal";
import TheLoader from "@/components/TheLoader/TheLoader";
import debounce from "@/lib/debounce";

const ERROR_PHONE_NUMBER_EMPTY = "Пожалуйста укажите номер телефона";
const ERROR_PHONE_NUMBER_INVALID_LENGTH = "Пожалуйста укажите корректный номер телефона";
const ERROR_ORDER_NUMBER_INVALID_CHARACTERS = "Номер заказа должен состоять из цифр";
const ERROR_ORDER_NUMBER_INVALID_LENGTH =
  "Номер заказа должен состоять из 6-и цифр. Если цифр 5, добавьте 0 в начало номера";
const ERROR_BARCODE_NUMBER_INVALID_CHARACTERS = "Штрих-код должен состоять из цифр";
const ERROR_BARCODE_AND_ORDER_EMPTY = "Пожалуйста укажите номер заказа или штрих-код";

const formatPhoneNumber = (event, phoneNumberComponent) => {
  let eventValue = event.target.value.trim();
  let value = eventValue.trim().replace(/\D/g, "");

  if (eventValue[0] === "+") {
    value = value.slice(1);
  } else if (eventValue[0] === "8" && eventValue.length >= 11) {
    value = value.slice(1);
  }

  const replacedInput = value.match(/(\d{0,3})(\d{0,3})(\d{0,4})/);

  phoneNumberComponent.value = !replacedInput[2]
    ? replacedInput[1]
    : "(" + replacedInput[1] + ") " + replacedInput[2] + (replacedInput[3] ? "-" + replacedInput[3] : "");
};
const formatPhoneNumberDebounced = debounce(formatPhoneNumber, 100);

export default {
  name: "TheRequestResultForm",
  data() {
    return {
      phoneNumberPrefix: null,
      errorText: "",
      phoneNumberError: false,
      isShowPhoneCheckMark: false,
      isShowOrderCheckMark: false,
      isShowBarcodeCheckMark: false,
      orderNumberError: false,
      barcodeNumberError: false,
      orderHelpModalShowed: false,
      barcodeHelpModalShowed: false,
      tooLongOrderNumberTooltipShowed: false,
      currentTab: true,
      blinkActiveTab: false
    };
  },
  computed: {
    ...mapGetters({
      ONLINE: "ONLINE",
      ERROR: "ANALISIS_RESULTS_MODULE/ERROR",
      ANALISIS_RESULTS_REQUESTING: "ANALISIS_RESULTS_MODULE/ANALISIS_RESULTS_REQUESTING",
      REQUEST_TIMEOUT: "ANALISIS_RESULTS_MODULE/REQUEST_TIMEOUT"
    }),
    phoneNumber() {
      return this.$refs.phoneNumberComponent ? this.$refs.phoneNumberComponent.value : "";
    },
    orderNumber() {
      return this.$refs.orderNumberComponent ? this.$refs.orderNumberComponent.value : "";
    },
    barcodeNumber() {
      return this.$refs.barcodeNumberComponent ? this.$refs.barcodeNumberComponent.value : "";
    },
    isPhoneNumberCorrect() {
      return this.phoneNumber.length === 14;
    },
    isOrderNumberCorrect() {
      return this.orderNumber.length === 6;
    },
    isBarcodeCorrect() {
      return this.barcodeNumber.length >= 6 && this.barcodeNumber.length <= 13;
    }
  },
  methods: {
    ...mapActions({
      REQUEST_ANALISIS_RESULTS: "ANALISIS_RESULTS_MODULE/REQUEST_ANALISIS_RESULTS",
      RESET_ERROR: "ANALISIS_RESULTS_MODULE/RESET_ERROR",
      CANCEL_ANALISIS_RESULTS_REQUESTING: "ANALISIS_RESULTS_MODULE/CANCEL_ANALISIS_RESULTS_REQUESTING"
    }),
    closePhoneNumberTooltip() {
      setTimeout(() => {
        this.tooLongOrderNumberTooltipShowed = false;
      }, 100);
    },
    showOrderHelpModal() {
      this.orderHelpModalShowed = true;
    },
    showBarcodeHelpModal() {
      this.barcodeHelpModalShowed = true;
    },
    closeOrderHelpModal() {
      this.orderHelpModalShowed = false;
    },
    closeBarcodeHelpModal() {
      this.barcodeHelpModalShowed = false;
    },
    changeFocusToBarcodeNumber() {
      const HTMLInput = this.$refs.barcodeNumberComponent.$refs[this.$refs.barcodeNumberComponent.ref];

      this.currentTab = false;
      this.activeLinkToBarcode();
      this.formatBarcodeNumber({ target: { value: this.orderNumber } });
      this.changedOrderNumberHandler();
      this.errorText = "";
      this.tooLongOrderNumberTooltipShowed = false;
      this.$refs.orderNumberComponent.value = "";
      this.$nextTick(() => HTMLInput.focus());
      this.$nextTick(() => (this.blinkActiveTab = false));
    },
    activeLink() {
      const tabs = document.querySelector(".tabs");
      const tabButton = document.querySelectorAll(".tabs-link");
      tabs.onclick = e => {
        const id = e.target.dataset.id;
        if (id) {
          tabButton.forEach(btn => {
            btn.classList.remove("active");
          });
          e.target.classList.add("active");
          const element = document.getElementById(id);
          element.classList.add("active");
        }
      };
    },
    activeLinkToBarcode() {
      this.blinkActiveTab = true;
      document.querySelectorAll(".tabs-link").forEach(btn => {
        btn.classList.remove("active");
      });
      document.getElementById("barcode").classList.add("active");
      this.hideOrderCheckMark();
    },
    showPhoneCheckMark() {
      this.isShowPhoneCheckMark = true;
    },
    hidePhoneCheckMark() {
      this.isShowPhoneCheckMark = false;
    },
    showOrderCheckMark() {
      this.isShowOrderCheckMark = true;
    },
    hideOrderCheckMark() {
      this.isShowOrderCheckMark = false;
    },
    showBarcodeCheckMark() {
      this.isShowBarcodeCheckMark = true;
    },
    hideBarcodeCheckMark() {
      this.isShowBarcodeCheckMark = false;
    },
    calculatePhoneCheckMark() {
      if (
        this.$refs.phoneNumberComponent.value.match(/(\d{3,3})-(\d{4,4})/) ||
        this.$refs.phoneNumberComponent.value.match(/(\d{11,11})/)
      ) {
        this.showPhoneCheckMark();
      } else {
        this.hidePhoneCheckMark();
      }
    },
    calculateOrderCheckMark() {
      if (this.$refs.orderNumberComponent.value.match(/(\d{6,6})/)) {
        this.showOrderCheckMark();
      } else {
        this.hideOrderCheckMark();
      }
    },
    calculateBarcodeCheckMark() {
      if (this.$refs.barcodeNumberComponent.value.match(/(\d{6,8})/)) {
        this.showBarcodeCheckMark();
      } else {
        this.hideBarcodeCheckMark();
      }
    },
    calculateCheckMarkDebounced() {
      //debounce(this.calculatePhoneCheckMark, 3000);
      this.calculatePhoneCheckMark();
    },
    phoneInputHandler(event) {
      formatPhoneNumberDebounced(event, this.$refs.phoneNumberComponent);
      this.calculateCheckMarkDebounced(event, this.$refs.phoneNumberComponent);
    },
    formatOrderNumber(event) {
      this.$refs.orderNumberComponent.value = event.target.value.slice(0, 13).replace(/[^0-9]/g, "");
      this.tooLongOrderNumberTooltipShowed = event.target.value.replace(/ /g, "").length > 6;
      this.calculateOrderCheckMark();
    },
    noTooltipCallback() {
      if (this.orderNumber.length > 6) {
        this.orderNumberError = true;
        this.errorText = ERROR_ORDER_NUMBER_INVALID_LENGTH;
      }

      this.tooLongOrderNumberTooltipShowed = false;
    },
    formatBarcodeNumber(event) {
      this.$refs.barcodeNumberComponent.value = event.target.value.slice(0, 8).replace(/[^0-9]/g, "");
      this.calculateBarcodeCheckMark();
    },
    changedPhoneNumberHandler() {
      this.phoneNumberError = false;
    },
    changedOrderNumberHandler() {
      this.barcodeNumberError = false;
      this.orderNumberError = false;
    },
    changedBarcodeNumberHandler() {
      this.barcodeNumberError = false;
      this.orderNumberError = false;
    },
    async submit() {
      this.errorText = "";
      if (!this.phoneNumber.length) {
        this.phoneNumberError = true;
        this.errorText = ERROR_PHONE_NUMBER_EMPTY;

        return;
      }

      if (this.phoneNumber.length !== 14) {
        this.phoneNumberError = true;
        this.errorText = ERROR_PHONE_NUMBER_INVALID_LENGTH;

        return;
      }

      if (!this.orderNumber && !this.barcodeNumber) {
        this.orderNumberError = true;
        this.barcodeNumberError = true;
        this.errorText = ERROR_BARCODE_AND_ORDER_EMPTY;

        return;
      }

      if (this.orderNumber) {
        if (this.orderNumber.length !== 6) {
          this.orderNumberError = true;
          this.errorText = ERROR_ORDER_NUMBER_INVALID_LENGTH;

          return;
        }

        if (!this.orderNumber.match(/^[0-9]+$/)) {
          this.orderNumberError = true;
          this.errorText = ERROR_ORDER_NUMBER_INVALID_CHARACTERS;

          return;
        }
      }

      if (this.barcodeNumber) {
        if (!this.barcodeNumber.match(/^[0-9]+$/)) {
          this.barcodeNumberError = true;
          this.errorText = ERROR_BARCODE_NUMBER_INVALID_CHARACTERS;

          return;
        }
      }

      const requestData = {
        phone: `${this.phoneNumberPrefix.replace(/\+7/g, "8")}${this.phoneNumber.replace(/\D/g, "")}`,
        orderNumber: this.orderNumber,
        barcode: this.barcodeNumber
      };
      let jsonSavedForm = JSON.stringify(requestData);
      sessionStorage.setItem("user_form", jsonSavedForm);
      this.REQUEST_ANALISIS_RESULTS(requestData);
    },
    tooltipEvent(event) {
      if (this.tooLongOrderNumberTooltipShowed) {
        event.preventDefault();
        if (event.keyCode === 13) {
          this.changeFocusToBarcodeNumber();
        } else if (event.keyCode === 27) {
          this.noTooltipCallback();
        }
      }
    },
    focusstartEvent() {
      this.phoneNumberPrefix = "+7";
    },
    focusoutEvent() {
      if (!this.phoneNumber) {
        this.phoneNumberPrefix = null;
      }
    },
    checkSubmitAccess(event) {
      if (
        this.ANALISIS_RESULTS_REQUESTING ||
        this.ERROR ||
        this.orderHelpModalShowed ||
        this.barcodeHelpModalShowed ||
        this.tooLongOrderNumberTooltipShowed
      ) {
        event.preventDefault();
      }
    }
  },
  components: {
    "vue-input-component": InputComponent,
    "vue-the-modal": TheModal,
    "vue-the-loader": TheLoader
  }
};
</script>

<style scoped lang="scss" src="./TheRequestResultForm.scss"></style>
