function loadScript(url) {
    const loadedScripts = new Map();
    return function() {
        return new Promise((resolve, reject) => {
            if (loadedScripts.has(url)) {
                resolve();
                return;
            }
            const script = document.createElement("script");
            loadedScripts.set(url, true);
            script.type = "text/javascript";
            script.src = url;
            script.onload = resolve;
            script.onerror = reject;
            document.head.appendChild(script);
        });
    };
}
export { loadScript };
