<template>
  <nav class="navbar-wrapper" v-if="windowWidth < 873">
    <h3 class="title">
      <span class="title-text">РЕЗУЛЬТАТЫ ИССЛЕДОВАНИЙ</span>
    </h3>
    <div class="burger-icon-wrapper" :class="{ checked: isBurgerChecked }" @click="toggleBurger">
      <div class="burger-icon burger-icon-1"></div>
      <div class="burger-icon burger-icon-2"></div>
      <div class="burger-icon burger-icon-3"></div>
    </div>
    <div class="burger-content" :class="{ showed: isBurgerChecked }">
      <router-link tag="a" to="/">Главная</router-link>
      <div class="end-line"></div>
    </div>
  </nav>
  <nav class="navbar-wrapper-large" v-else>
    <div class="navbar-container">
      <router-link tag="a" to="/">Главная</router-link>
      <router-link tag="a" class="active" to="/result">Результаты</router-link>
    </div>
  </nav>
</template>

<script>
export default {
  name: "TheNavbar",
  data() {
    return {
      windowWidth: 0,
      isBurgerChecked: false
    };
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.getWindowWidth);

      this.getWindowWidth();
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.getWindowWidth);
  },
  methods: {
    toggleBurger() {
      this.isBurgerChecked = !this.isBurgerChecked;
    },
    getWindowWidth() {
      this.windowWidth = document.documentElement.clientWidth;
    }
  },
  props: {
    isOnMobilePrint: {
      type: Boolean,
      default: true
    }
  }
};
</script>

<style scoped lang="scss" src="./TheNavbar.scss"></style>
