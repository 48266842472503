<template>
  <div>
    <div class="result-table-wrapper large-screen" v-if="windowWidth > 872">
      <div class="wrap" v-if="formattedServices && formattedServices.length">
        <div class="table-wrapper">
          <div class="patient-frame">
            <caption>
              <a v-show="formattedPDFFiles && formattedPDFFiles.length" class="to-pdf-link" href="#pdf-files-place">
                Бланки результатов
              </a>
            </caption>
            <div v-if="patientFIO" class="patient-block">
              <span class="patient-fio">{{ patientFIO }}</span>
            </div>
            <div class="analyze-block">
              <span v-if="orderDate"
                >дата взятия материала: <span class="analyze-item">{{ orderDate }}</span></span
              >
              <span v-if="orderNumber"
                >номер заказа: <span class="analyze-item">{{ orderNumber }}</span></span
              >
              <span v-if="barcodeNumber"
                >номер штрих-кода: <span class="analyze-item">{{ barcodeNumber }}</span></span
              >
            </div>
            <div class="image-frame">
              <a class="update-result tooltip-custom" @click.prevent="updateResultEvent" :disabled="!!REQUEST_TIMEOUT">
                <span class="tooltip-text">Обновить</span>
                <img src="/assets/images/update-result.png" alt="Обновить" class="revert-color" />
              </a>
              <a class="print-doc tooltip-custom" href="javascript:(print());">
                <span class="tooltip-text">Печать</span>
                <img src="/assets/images/print.svg" alt="Печать" class="revert-color" />
              </a>
              <a class="delete-result tooltip-custom" @click="clearResultEvent">
                <span class="tooltip-text">Очистить</span>
                <img src="/assets/images/remove-result.png" alt="Очистить" class="revert-color" />
              </a>
            </div>
          </div>

          <div class="card" v-for="(service, index) of formattedServices" :key="index">
            <div class="card-header">
              <span class="btn-text">
                {{ service.name }}
              </span>
            </div>
            <div class="card-body">
              <div class="card">
                <div class="card-body test-card">
                  <table class="table-responsive card-list-table">
                    <tbody>
                      <tr class="date-cell">
                        <th scope="row" width="55%">тест</th>
                        <th scope="row" width="15%">дата</th>
                        <th scope="row" width="15%">рез.</th>
                        <th scope="row" width="15%">норма</th>
                      </tr>
                      <tr
                        :class="
                          getClass(
                            `${test.normMin}`,
                            `${test.normMax}`,
                            `${test.result}`,
                            `${test.resultStr}`,
                            `${test.normalResult}`
                          )
                        "
                        v-for="(test, index) of service.tests"
                        :key="index"
                      >
                        <td>{{ test.name || "&mdash;" }}</td>
                        <td class="date-cell">{{ test.date || "&mdash;" }}</td>
                        <td>{{ test.resultStr || "&mdash;" }}</td>
                        <td>{{ test.normalResult || "&mdash;" }}</td>
                        <td class="page-link">
                          <a :href="locale" @click.prevent="switchLocale">{{ locale }}</a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="no-results-wrapper">
        <div class="no-results">
          <p>Данных нет.</p>
          <p>Вернитесь на главную страницу и попробуйте снова.</p>
        </div>
      </div>
    </div>
    <div v-else>
      <div id="servicesAccordion" class="accordion" v-if="formattedServices && formattedServices.length">
        <div class="patient-frame">
          <caption>
            <a v-show="formattedPDFFiles && formattedPDFFiles.length" class="to-pdf-link" href="#pdf-files-place">
              Бланки результатов
            </a>
          </caption>
          <div v-if="patientFIO" class="patient-block">
            <span class="patient-fio">{{ patientFIO }}</span>
          </div>
          <div class="analyze-block">
            <span v-if="orderDate"
              >дата взятия материала: <span class="analyze-item">{{ orderDate }}</span
              ><br
            /></span>
            <span v-if="orderNumber"
              >номер заказа: <span class="analyze-item">{{ orderNumber }}</span
              ><br
            /></span>
            <span v-if="barcodeNumber"
              >номер штрих-кода: <span class="analyze-item">{{ barcodeNumber }}</span></span
            >
          </div>
          <div class="image-frame">
            <a class="update-result tooltip-custom" @click.prevent="updateResultEvent" :disabled="!!REQUEST_TIMEOUT">
              <span class="tooltip-text">Обновить</span>
              <img src="/assets/images/update-result.png" alt="Обновить" class="revert-color" />
            </a>
            <a class="print-doc tooltip-custom" href="javascript:(print());">
              <span class="tooltip-text">Печать</span>
              <img src="/assets/images/print.svg" alt="Печать" class="revert-color" />
            </a>
            <a class="delete-result tooltip-custom" @click="clearResultEvent">
              <span class="tooltip-text">Очистить</span>
              <img src="/assets/images/remove-result.png" alt="Очистить" class="revert-color" />
            </a>
          </div>
        </div>
        <div class="card" v-for="(service, index) of formattedServices" :key="index">
          <div :id="`heading${index}`" class="card-header">
            <h2 class="card-header-title">
              <button
                class="btn btn-block btn-custom"
                type="button"
                data-toggle="collapse"
                :data-target="`#collapse${index}`"
                aria-expanded="false"
                :aria-controls="`collapse${index}`"
              >
                <span class="btn-text">
                  {{ service.name }}
                  <img src="/assets/images/angle.png" class="angle" />
                </span>
              </button>
            </h2>
          </div>

          <div
            :id="`collapse${index}`"
            class="collapse"
            :aria-labelledby="`heading${index}`"
            data-parent="#servicesAccordion"
          >
            <div class="card-body" v-if="windowWidth > 872">
              <div class="card">
                <div class="card-body test-card">
                  <table class="table">
                    <tbody>
                      <tr>
                        <th scope="row" width="40%">тест</th>
                        <th scope="row" width="20%">дата</th>
                        <th scope="row" width="20%">рез.</th>
                        <th scope="row" width="20%">норма</th>
                      </tr>
                      <tr
                        :class="
                          getClass(
                            `${test.normMin}`,
                            `${test.normMax}`,
                            `${test.result}`,
                            `${test.resultStr}`,
                            `${test.normalResult}`
                          )
                        "
                        v-for="(test, index) of service.tests"
                        :key="index"
                      >
                        <td>{{ test.name || "&mdash;" }}</td>
                        <td>{{ test.date || "&mdash;" }}</td>
                        <td>{{ test.resultStr || "&mdash;" }}</td>
                        <td>{{ test.normalResult || "&mdash;" }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div class="card-body" v-else>
              <div class="card">
                <div class="card-body test-card">
                  <table class="table">
                    <tbody>
                      <tr>
                        <th scope="row" width="50%">тест</th>
                        <th scope="row" width="20%">
                          <button
                            class="notActiveBtn"
                            @click="
                              activeBtn = 'dateBtn';
                              showCell = !showCell;
                            "
                            :class="{ activeBtn: activeBtn === 'dateBtn' }"
                          >
                            дата
                          </button>
                        </th>
                        <th scope="row" width="30%" colspan="2">
                          <button
                            class="notActiveBtn"
                            @click="
                              activeBtn = 'resNormBtn';
                              showCell = !showCell;
                            "
                            :class="{ activeBtn: activeBtn === 'resNormBtn' }"
                          >
                            <span>рез.</span>
                            <span>норма</span>
                          </button>
                        </th>
                      </tr>
                      <tr
                        :class="
                          getClass(
                            `${test.normMin}`,
                            `${test.normMax}`,
                            `${test.result}`,
                            `${test.resultStr}`,
                            `${test.normalResult}`
                          )
                        "
                        v-for="(test, index) of service.tests"
                        :key="index"
                      >
                        <td>{{ test.name || "&mdash;" }}</td>
                        <td v-if="!showCell" colspan="3">{{ test.date || "&mdash;" }}</td>
                        <td v-if="showCell">{{ test.resultStr || "&mdash;" }}</td>
                        <td v-if="showCell">{{ test.normalResult || "&mdash;" }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="no-results-wrapper">
        <div class="no-results">
          <p>Данных нет.</p>
          <p>Вернитесь на главную страницу и попробуйте снова.</p>
        </div>
      </div>
    </div>
    <div id="pdf-files-place" class="pdf-files-wrapper" v-show="formattedPDFFiles && formattedPDFFiles.length">
      <div class="pdf-buttons-panel">
        <h3 class="title">Бланки результатов</h3>
        <div class="pdf-loader" v-for="(pdffile, index) of formattedPDFFiles" :key="index">
          <p>{{ pdffile.name }}</p>
          <div class="button-place">
            <button @click="openPDFFile(pdffile.url, pdffile.viewerId)" class="button-to-look">Просмотреть</button>
            <a download="true" :href="pdffile.url"><button>Загрузить</button></a>
          </div>
        </div>
      </div>
    </div>
    <transition name="fade">
      <div class="modal-cackground" v-if="ANALISIS_RESULTS_REQUESTING || ERROR || openedPDFModalWrapper"></div>
    </transition>
    <transition name="size">
      <div class="vue-the-modal-wrapper" v-if="ANALISIS_RESULTS_REQUESTING">
        <vue-the-modal class="vue-the-modal">
          <template v-slot:title>
            <h2 class="vue-the-modal-title">Результат</h2>
          </template>
          <template v-slot:body>
            <div class="vue-the-modal-body">
              <p class="vue-the-modal-message">
                Ваш запрос принят в обработку.
                <br />
                Ожидайте результатов.
              </p>
              <div class="vue-the-loader-wrapper">
                <vue-the-loader class="vue-the-loader" />
              </div>
              <span class="vue-the-modal-btns">
                <button class="vue-the-modal-ok-btn" @click="CANCEL_ANALISIS_RESULTS_REQUESTING">Отмена</button>
              </span>
            </div>
          </template>
        </vue-the-modal>
      </div>
    </transition>
    <transition name="size">
      <div class="vue-the-modal-wrapper" v-if="ERROR">
        <vue-the-modal
          class="vue-the-modal"
          :defaultCloseButton="true"
          :closeModalHandler="RESET_ERROR"
          :outsideModalHandler="RESET_ERROR"
        >
          <template v-slot:title>
            <h2 class="vue-the-modal-title">{{ ERROR.title }}</h2>
          </template>
          <template v-slot:body>
            <div class="vue-the-modal-body">
              <p class="vue-the-modal-message">
                {{ ERROR.message }}
              </p>
              <span class="vue-the-modal-btns">
                <button class="vue-the-modal-ok-btn" @click="RESET_ERROR">ОК</button>
              </span>
            </div>
          </template>
        </vue-the-modal>
      </div>
    </transition>
    <transition name="size">
      <div class="vue-the-modal-wrapper" v-show="openedPDFModalWrapper">
        <vue-the-modal
          class="vue-the-modal"
          :defaultCloseButton="true"
          :closeModalHandler="closeModalPDFWrapper"
          :outsideModalHandler="closeModalPDFWrapper"
        >
          <template v-slot:title>
            <h2 class="vue-the-modal-title">Просмотр бланка результатов</h2>
          </template>
          <template v-slot:body>
            <div class="vue-the-modal-body">
              <div class="vue-the-modal-message">
                <div
                  v-for="(pdffile, index) of formattedPDFFiles"
                  :key="index"
                  :id="pdffile.viewerId"
                  class="pdf-wrapper"
                  style="display: none"
                ></div>
              </div>
              <span class="vue-the-modal-btns">
                <button class="vue-the-modal-ok-btn" @click="closeModalPDFWrapper">ОК</button>
              </span>
            </div>
          </template>
        </vue-the-modal>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import TheModal from "@/components/TheModal/TheModal";
import TheLoader from "@/components/TheLoader/TheLoader";
import { createPDFViewer } from "../../services/loadPDFjs";

export default {
  name: "TheResultTable",
  data() {
    return {
      windowWidth: 0,
      windowHeight: 0,
      localRequest: {},
      localAnalisisResult: [],
      openedPDFModalWrapper: false,
      testPDFView: false, //удалить как заработает
      activeBtn: "resNormBtn",
      showCell: true
    };
  },
  components: {
    "vue-the-modal": TheModal,
    "vue-the-loader": TheLoader
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.getWindowWidth);

      this.getWindowWidth();
    });

    if (!this.analisisResults) {
      if (sessionStorage.getItem("analisis_result")) {
        this.localAnalisisResult = JSON.parse(sessionStorage.getItem("analisis_result"));
        this.localRequest = JSON.parse(sessionStorage.getItem("user_form"));
        this.SET_ANALISIS(this.localAnalisisResult);
      }
      navigator.serviceWorker.ready.then(registration => {
        registration.active.postMessage("LOAD_SAVED_ANALISIS_RESULTS");
      });
    }
  },
  computed: {
    ...mapGetters({
      analisisResults: "ANALISIS_RESULTS_MODULE/ANALISIS_RESULTS",
      ANALISIS_RESULTS_REQUESTING: "ANALISIS_RESULTS_MODULE/ANALISIS_RESULTS_REQUESTING",
      ERROR: "ANALISIS_RESULTS_MODULE/ERROR",
      REQUEST_TIMEOUT: "ANALISIS_RESULTS_MODULE/REQUEST_TIMEOUT"
    }),
    formattedServices() {
      const formattedServices = [];

      if (!this.analisisResults || !this.analisisResults.length) {
        return formattedServices;
      }

      for (let analisisResult of this.analisisResults) {
        if (!analisisResult.Services || !analisisResult.Services.length) {
          continue;
        }

        for (let service of analisisResult.Services) {
          const fromatedService = {
            name: service.ServiceName,
            tests: []
          };
          if (service.Tests && service.Tests.length) {
            for (let test of service.Tests) {
              fromatedService.tests.push({
                name: test.TestName,
                date: test.ResultDate,
                resultStr: test.ResultStr,
                normalResult: test.NormStr,
                normMax: test.NormMax,
                normMin: test.NormMin,
                result: test.Result
              });
            }
          }

          formattedServices.push(fromatedService);
        }
      }

      return formattedServices;
    },
    patientFIO() {
      let fio = "";
      if (!this.analisisResults || !this.analisisResults) {
        return fio.replace(/-/g, "");
      }

      const patient = this.analisisResults[0];
      if (patient) {
        fio = patient.FullName;
      }

      return fio.replace(/-/g, "");
    },
    orderDate() {
      let orderDate = "";
      if (!this.analisisResults || !this.analisisResults.length) {
        return orderDate;
      }
      orderDate = this.analisisResults[0];
      const results = this.analisisResults;
      if (Array.isArray(results)) {
        const order = results[0];
        if (order) {
          orderDate = order.MaterialDate;
        }
      }
      return orderDate;
    },
    orderNumber() {
      let orderNumber = "";
      if (!this.analisisResults || !this.analisisResults.length) {
        return orderNumber;
      }

      if (sessionStorage.getItem("analisis_result")) {
        const order = JSON.parse(sessionStorage.getItem("user_form"));
        if (order) {
          orderNumber = order.orderNumber;
        }
      }
      return orderNumber;
    },
    barcodeNumber() {
      let barcodeNumber = "";
      if (!this.analisisResults || !this.analisisResults.length) {
        return barcodeNumber;
      }

      if (sessionStorage.getItem("analisis_result")) {
        const barcode = JSON.parse(sessionStorage.getItem("user_form"));
        if (barcode) {
          barcodeNumber = barcode.barcode;
        }
      }
      return barcodeNumber;
    },
    formattedPDFFiles() {
      const formattedPDFFiles = [];
      const pdfApiPath = process.env.VUE_APP_FILES_API_PREFIX;
      const idFilesPrefix = "?id=";
      const viewerIdPrefix = "pdf-viewer";

      console.log(this.analisisResults);
      if (!this.analisisResults || !this.analisisResults.length) {
        return formattedPDFFiles;
      }

      for (let analisisResult of this.analisisResults) {
        if (!analisisResult.PDFs || !analisisResult.PDFs.length) {
          continue;
        }

        for (let pdfFile of analisisResult.PDFs) {
          const fromatedPDFFile = {
            name: pdfFile.Name,
            id: pdfFile.Id,
            url: pdfApiPath + idFilesPrefix + pdfFile.Id,
            viewerId: viewerIdPrefix + pdfFile.Id
          };
          formattedPDFFiles.push(fromatedPDFFile);
        }
      }

      return formattedPDFFiles;
    }
  },
  methods: {
    ...mapActions({
      SET_ANALISIS: "ANALISIS_RESULTS_MODULE/SET_ANALISIS",
      REQUEST_ANALISIS_RESULTS: "ANALISIS_RESULTS_MODULE/REQUEST_ANALISIS_RESULTS",
      RESET_ERROR: "ANALISIS_RESULTS_MODULE/RESET_ERROR",
      CANCEL_ANALISIS_RESULTS_REQUESTING: "ANALISIS_RESULTS_MODULE/CANCEL_ANALISIS_RESULTS_REQUESTING",
      RESET_ANALISIS_RESULTS: "ANALISIS_RESULTS_MODULE/RESET_ANALISIS_RESULTS"
    }),
    getClass(min, max, res, normStr, normRes) {
      if (normRes.match(/(\d{1,20})/)) {
        if (parseFloat(res) > parseFloat(max) || parseFloat(res) < parseFloat(min)) {
          this.class = "outNorma";
          return this.class;
        }
      } else {
        if (normRes !== normStr) {
          this.class = "outNorma";
          return this.class;
        }
      }
    },
    getWindowWidth() {
      this.windowWidth = document.documentElement.clientWidth;
    },
    async updateResultEvent() {
      this.localRequest = JSON.parse(sessionStorage.getItem("user_form"));
      console.log(this.localRequest);
      this.$store.dispatch("GET_TOKEN").then(() => {
        this.REQUEST_ANALISIS_RESULTS(this.localRequest);
      });
    },
    clearResultEvent() {
      sessionStorage.clear();
      this.RESET_ANALISIS_RESULTS();
      this.localRequest = null;
      this.localAnalisisResult = null;
    },
    openPDFFile(path, viewerId) {
      this.openPDFModalWrapper();
      createPDFViewer()
        .then(() => {
          const viewerOptions = {
            height: "100%",
            width: "100%",
            fallbackLink:
              "<p>Ваш браузер не поддерживает просмотр файла <a href='[url]' download>Ссылка на файл</a></p>"
          };
          this.openCurrentPDFViewer(viewerId);
          window.PDFObject.embed(path, `#${viewerId}`, viewerOptions);
        })
        .catch(error => {
          console.log(error);
        });
    },
    closeModalPDFWrapper() {
      this.openedPDFModalWrapper = false;
      this.closeAllPDFViewers();
    },
    openPDFModalWrapper() {
      this.openedPDFModalWrapper = true;
    },
    openCurrentPDFViewer(viewerId) {
      const currentViewer = document.querySelector(`#${viewerId}`);
      currentViewer.style.display = "block";
    },
    closeAllPDFViewers() {
      const pdfViewers = document.querySelectorAll(".pdf-wrapper");
      for (let pdfViewer of pdfViewers) {
        pdfViewer.style.display = "none";
      }
    }
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.getWindowWidth);
  }
};
</script>

<style scoped lang="scss" src="./TheResultTable.scss"></style>
