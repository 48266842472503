export default {
    namespaced: true,
    state: {
        SAVED_ANALISIS_RESULTS_LOADED: "SAVED_ANALISIS_RESULTS_LOADED",
        DEFFERED_ANALSIS_RESULTS_REQUEST_AVAILABLE: "DEFFERED_ANALSIS_RESULTS_REQUEST_AVAILABLE"
    },
    actions: {
        INIT_SW_MESSAGE_EVENT_HANDLER({ dispatch, getters }) {
            if (getters.SERVICE_WORKER) {
                getters.SERVICE_WORKER.addEventListener("message", event =>
                    dispatch("SW_MESSAGE_EVENT_HANDLER", event)
                );
            }
        },
        SW_MESSAGE_EVENT_HANDLER({ dispatch, state }, event) {
            const eventMessage = event.data.message;
            const eventData = event.data.data;

            if (eventMessage === state.SAVED_ANALISIS_RESULTS_LOADED && eventData) {
                dispatch("ANALISIS_RESULTS_MODULE/SET_ANALISIS_RESULTS", eventData.data[0].Orders, { root: true });
            }

            if (eventMessage === state.DEFFERED_ANALSIS_RESULTS_REQUEST_AVAILABLE) {
                dispatch("ANALISIS_RESULTS_MODULE/REQUEST_ANALISIS_RESULTS", JSON.parse(eventData.body), {
                    root: true
                });
            }
        }
    },
    getters: {
        NAVIGATOR() {
            return navigator || {};
        },
        SERVICE_WORKER(state, getters) {
            return "serviceWorker" in getters.NAVIGATOR ? getters.NAVIGATOR.serviceWorker : null;
        }
    }
};
