import Vue from "vue";
import Vuex from "vuex";
import analisisResultsModule from "./modules/analisisResultsModule";
import serviceWorkerModule from "./modules/serviceWorkerModule";
import { getToken } from "@/services/sessionService.js";

Vue.use(Vuex);

const ERRORS = {
    UNDEFINED: {
        title: "ОШИБКА",
        message: "Извините, сервис временно недоступен. Пожалуйста, попробуйте зайти к нам чуть позже."
    }
};

export default new Vuex.Store({
    state: {
        online: true,
        loading: false,
        sessionError: null
    },
    actions: {
        SET_ONLINE_STATUS({ commit }, online) {
            commit("SET_ONLINE_STATUS", online);
        },
        START_LOADING({ commit }) {
            commit("SET_LOADING_STATUS", true);
        },
        STOR_LOADING({ commit }) {
            commit("SET_LOADING_STATUS", false);
        },
        SET_SESSION_ERROR({ commit }, error) {
            commit("SET_SESSION_ERROR", error);
        },
        async GET_TOKEN({ commit }) {
            try {
                // throw new Error()
                await getToken();
            } catch (error) {
                if (!error) {
                    commit("SET_SESSION_ERROR", ERRORS.UNDEFINED);
                } else if (!error.response) {
                    commit("SET_SESSION_ERROR", ERRORS.UNDEFINED);
                } else if (!error.response.data) {
                    commit("SET_SESSION_ERROR", ERRORS.UNDEFINED);
                } else if (!error.response.data.error) {
                    commit("SET_SESSION_ERROR", ERRORS.UNDEFINED);
                } else {
                    if (error.response.status === 429) {
                        commit("SET_SESSION_ERROR", {
                            title: "Наш ответ",
                            message: error.response.data.amountOfTime
                        });
                    } else {
                        commit("SET_SESSION_ERROR", {
                            title: "Наш ответ",
                            message: error.response.data.error
                        });
                    }
                }

                console.error(error);
            }
        }
    },
    mutations: {
        SET_ONLINE_STATUS(state, online) {
            state.online = online;
        },
        SET_LOADING_STATUS(state, status) {
            state.loading = status;
        },
        SET_SESSION_ERROR(state, sessionError) {
            state.sessionError = sessionError;
        }
    },
    getters: {
        ONLINE(state) {
            return state.online;
        },
        LOADING(state) {
            return state.loading;
        },
        SESSION_ERROR(state) {
            return state.sessionError;
        }
    },
    modules: {
        ANALISIS_RESULTS_MODULE: analisisResultsModule,
        SERVICE_WORKER_MODULE: serviceWorkerModule
    }
});
