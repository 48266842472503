<template>
  <div class="loader">
    <div class="circle"></div>
    <div class="circle"></div>
    <div class="circle"></div>
  </div>
</template>

<script>
export default {
  name: "TheLoader"
};
</script>

<style scoped lang="scss" src="./TheLoader.scss"></style>
