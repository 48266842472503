import Vue from "vue";
import Router from "vue-router";
import MainView from "@/views/MainView/MainView.vue";
import ResultView from "@/views/ResultView/ResultView.vue";
import CheckCodeView from "@/views/CheckCodeView/CheckCodeView.vue";

Vue.use(Router);

const routes = [
    {
        path: "/",
        name: "MainViewRoute",
        component: MainView
    },
    {
        path: "/result",
        name: "ResultViewRoute",
        component: ResultView
    },
    {
        path: "/check/*",
        name: "CheckCodeView",
        component: CheckCodeView
    }
];

const router = new Router({
    mode: "history",
    base: process.env.BASE_URL,
    routes
});

export default router;
