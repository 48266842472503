<template>
  <div>
    <div class="result-table-wrapper large-screen" v-if="windowWidth > 872">
      <div class="wrap" v-if="formattedServices && formattedServices.length">
        <div class="patient-frame">
          <div class="patient-block">
            <span class="patient-fio">{{ patientFIO }}</span>
            <a href="/" class="go-out-link">выйти</a>
          </div>
          <div class="analyze-block">
            <span v-if="orderDate"
              >дата взятия материала: <span class="analyze-item">{{ orderDate }}</span></span
            >
            <span v-if="orderNumber"
              >номер заказа: <span class="analyze-item">{{ orderNumber }}</span></span
            >
            <span v-if="barcodeNumber"
              >номер штрих-кода: <span class="analyze-item">{{ barcodeNumber }}</span></span
            >
          </div>
          <img src="/assets/images/logo-transparent.png" alt="" class="image-frame" />
        </div>
        <div class="card" v-for="(service, index) of formattedServices" :key="index">
          <div class="card-header">
            <span class="btn-text">
              {{ service.name }}
            </span>
          </div>
          <div class="card-body">
            <div class="card">
              <div class="card-body test-card">
                <table class="table-responsive card-list-table">
                  <tbody>
                    <tr class="date-cell">
                      <th v-if="locale === 'en'" scope="row" width="40%">test</th>
                      <th v-else scope="row" width="40%">тест</th>
                      <th v-if="locale === 'en'" scope="row" width="15%">date</th>
                      <th v-else scope="row" width="15%">дата</th>
                      <th v-if="locale === 'en'" scope="row" width="15%">result</th>
                      <th v-else scope="row" width="15%">рез.</th>
                      <th v-if="locale === 'en'" scope="row" width="15%">normal result</th>
                      <th v-else scope="row" width="15%">норма</th>
                      <th v-if="locale === 'en'" scope="row" width="15%">lang</th>
                      <th v-else scope="row" width="15%">язык</th>
                    </tr>
                    <tr
                      :class="
                        getClass(
                          `${test.normMin}`,
                          `${test.normMax}`,
                          `${test.result}`,
                          `${test.resultStr}`,
                          `${test.normalResult}`
                        )
                      "
                      v-for="(test, index) of service.tests"
                      :key="index"
                    >
                      <td>{{ test.name || "&mdash;" }}</td>
                      <td class="date-cell">{{ test.date || "&mdash;" }}</td>
                      <td>{{ test.resultStr || "&mdash;" }}</td>
                      <td>{{ test.normalResult || "&mdash;" }}</td>
                      <td class="page-link">
                        <a :href="locale" @click.prevent="switchLocale">{{ locale }}</a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="no-results-wrapper">
        <div class="no-results">
          <p>Данных нет.</p>
          <p>.....</p>
        </div>
      </div>
    </div>
    <div v-else>
      <div id="servicesAccordion" class="accordion" v-if="formattedServices && formattedServices.length">
        <div class="patient-frame">
          <div class="patient-block">
            <span class="patient-fio">{{ patientFIO }}</span>
            <a href="/" class="go-out-link">выйти</a>
          </div>
          <div class="analyze-block">
            <span v-if="orderDate"
              >дата взятия материала: <span class="analyze-item">{{ orderDate }}</span
              ><br
            /></span>
            <span v-if="orderNumber"
              >номер заказа: <span class="analyze-item">{{ orderNumber }}</span
              ><br
            /></span>
            <span v-if="barcodeNumber"
              >номер штрих-кода: <span class="analyze-item">{{ barcodeNumber }}</span></span
            >
          </div>
          <img src="/assets/images/logo-transparent.png" alt="" class="image-frame" />
        </div>
        <div class="card" v-for="(service, index) of formattedServices" :key="index">
          <div :id="`heading${index}`" class="card-header">
            <h2 class="card-header-title">
              <button
                class="btn btn-block btn-custom"
                type="button"
                data-toggle="collapse"
                :data-target="`#collapse${index}`"
                aria-expanded="false"
                :aria-controls="`collapse${index}`"
              >
                <span class="btn-text">
                  {{ service.name }}
                  <img src="/assets/images/angle.png" class="angle" />
                </span>
              </button>
            </h2>
          </div>

          <div
            :id="`collapse${index}`"
            class="collapse"
            :aria-labelledby="`heading${index}`"
            data-parent="#servicesAccordion"
          >
            <div class="card-body">
              <div class="card">
                <div class="card-body test-card">
                  <table class="table">
                    <tbody>
                      <tr>
                        <th v-if="locale === 'en'" scope="row" width="40%">test</th>
                        <th v-else scope="row" width="40%">тест</th>
                        <th v-if="locale === 'en'" scope="row" width="15%">date</th>
                        <th v-else scope="row" width="15%">дата</th>
                        <th v-if="locale === 'en'" scope="row" width="15%">result</th>
                        <th v-else scope="row" width="15%">рез.</th>
                        <th v-if="locale === 'en'" scope="row" width="15%">normal result</th>
                        <th v-else scope="row" width="15%">норма</th>
                        <th v-if="locale === 'en'" scope="row" width="15%">lang</th>
                        <th v-else scope="row" width="15%">язык</th>
                      </tr>
                      <tr
                        :class="
                          getClass(
                            `${test.normMin}`,
                            `${test.normMax}`,
                            `${test.result}`,
                            `${test.resultStr}`,
                            `${test.normalResult}`
                          )
                        "
                        v-for="(test, index) of service.tests"
                        :key="index"
                      >
                        <td>{{ test.name || "&mdash;" }}</td>
                        <td class="date-cell">{{ test.date || "&mdash;" }}</td>
                        <td>{{ test.resultStr || "&mdash;" }}</td>
                        <td>{{ test.normalResult || "&mdash;" }}</td>
                        <td class="page-link">
                          <a :href="locale" @click.prevent="switchLocale">{{ locale }}</a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="no-results-wrapper">
        <div class="no-results">
          <p>Данных нет.</p>
          <p>Вернитесь на главную страницу и попробуйте снова.</p>
        </div>
      </div>
    </div>

    <transition name="fade">
      <div class="modal-cackground" v-if="ANALISIS_RESULTS_REQUESTING || ERROR"></div>
    </transition>

    <transition name="size">
      <div class="vue-the-modal-wrapper" v-if="ANALISIS_RESULTS_REQUESTING">
        <vue-the-modal class="vue-the-modal">
          <template v-slot:title>
            <h2 class="vue-the-modal-title">Результат</h2>
          </template>
          <template v-slot:body>
            <div class="vue-the-modal-body">
              <p class="vue-the-modal-message">
                Ваш запрос принят в обработку.
                <br />
                Ожидайте результатов.
              </p>
              <div class="vue-the-loader-wrapper">
                <vue-the-loader class="vue-the-loader" />
              </div>
              <span class="vue-the-modal-btns">
                <button class="vue-the-modal-ok-btn" @click="CANCEL_ANALISIS_RESULTS_REQUESTING">Отмена</button>
              </span>
            </div>
          </template>
        </vue-the-modal>
      </div>
    </transition>

    <transition name="size">
      <div class="vue-the-modal-wrapper" v-if="ERROR">
        <vue-the-modal
          class="vue-the-modal"
          :defaultCloseButton="true"
          :closeModalHandler="RESET_ERROR"
          :outsideModalHandler="RESET_ERROR"
        >
          <template v-slot:title>
            <h2 class="vue-the-modal-title">{{ ERROR.title }}</h2>
          </template>
          <template v-slot:body>
            <div class="vue-the-modal-body">
              <p class="vue-the-modal-message">
                {{ ERROR.message }}
              </p>
              <span class="vue-the-modal-btns">
                <button class="vue-the-modal-ok-btn" @click="RESET_ERROR">ОК</button>
              </span>
            </div>
          </template>
        </vue-the-modal>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import TheModal from "@/components/TheModal/TheModal";
import TheLoader from "@/components/TheLoader/TheLoader";

function getUrlVars() {
  var vars = {};
  window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(m, key, value) {
    vars[key] = value;
  });
  return vars;
}

export default {
  name: "TheVerifyResultTable",
  data() {
    return {
      windowWidth: 0,
      windowHeight: 0,
      localRequest: {},
      localAnalisisResult: [],
      openedPDFModalWrapper: false,
      testPDFView: false, //удалить как заработает
      locale: "ru"
    };
  },
  components: {
    "vue-the-modal": TheModal,
    "vue-the-loader": TheLoader
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.getWindowWidth);

      this.getWindowWidth();
    });

    if (!this.analisisVerificationResults) {
      let code = window.location.href.split("code=", 2),
        date = "",
        guid = "";
      if (code.length === 2) {
        code = code[1].split("&", 3)[0];
        const otherParams = getUrlVars();
        date = otherParams["date"];
        guid = otherParams["guid"];

        const requestData = { code, date, guid };
        this.$recaptchaLoaded().then(() => this.REQUEST_ANALISIS_VERIFICATION_RESULTS(requestData));
      }
    }
  },
  computed: {
    ...mapGetters({
      analisisVerificationResults: "ANALISIS_RESULTS_MODULE/ANALISIS_VERIFICATION_RESULTS",
      ANALISIS_RESULTS_REQUESTING: "ANALISIS_RESULTS_MODULE/ANALISIS_RESULTS_REQUESTING",
      ERROR: "ANALISIS_RESULTS_MODULE/ERROR",
      REQUEST_TIMEOUT: "ANALISIS_RESULTS_MODULE/REQUEST_TIMEOUT"
    }),
    formattedServices() {
      const formattedServices = [];

      if (!this.analisisVerificationResults || !this.analisisVerificationResults.length) {
        return formattedServices;
      }

      for (let analisisResult of this.analisisVerificationResults) {
        if (!analisisResult.Services || !analisisResult.Services.length) {
          continue;
        }

        for (let service of analisisResult.Services) {
          const fromatedService = {
            name: service.ServiceName,
            tests: []
          };

          if (service.Tests && service.Tests.length) {
            for (let test of service.Tests) {
              if (this.canBeTranslated(test.TestName)) {
                fromatedService.tests.push({
                  name: this.translateCovidTestName(test.TestName),
                  date: this.translateDateTest(test.ResultDate),
                  resultStr: this.translateResult(test.ResultStr),
                  normalResult: this.translateResult(test.NormStr),
                  normMax: this.translateResult(test.NormMax),
                  normMin: this.translateResult(test.NormMin),
                  result: this.translateResult(test.Result)
                });
              } else {
                fromatedService.tests.push({
                  name: test.TestName,
                  date: test.ResultDate,
                  resultStr: test.ResultStr,
                  normalResult: test.NormStr,
                  normMax: test.NormMax,
                  normMin: test.NormMin,
                  result: test.Result
                });
              }
            }
          }

          formattedServices.push(fromatedService);
        }
      }

      return formattedServices;
    },
    patientFIO() {
      let fio = "";
      if (!this.analisisVerificationResults || !this.analisisVerificationResults.length) {
        return fio.replace(/-/g, "");
      }

      const patient = this.analisisVerificationResults[0].Patient;
      if (patient) {
        fio = patient.FullName;
      }

      return fio.replace(/-/g, "");
    },
    orderDate() {
      let orderDate = "";
      if (!this.analisisVerificationResults || !this.analisisVerificationResults.length) {
        return orderDate;
      }
      orderDate = this.analisisVerificationResults[0]?.OrderDate;
      const results = this.analisisVerificationResults;
      if (Array.isArray(results)) {
        const order = results[0];
        if (order) {
          orderDate = order.OrderDate;
        }
      }
      return orderDate;
    },
    orderNumber() {
      let orderNumber = "";
      if (!this.analisisResults || !this.analisisResults.length) {
        return orderNumber;
      }

      if (sessionStorage.getItem("analisis_result")) {
        const order = JSON.parse(sessionStorage.getItem("user_form"));
        if (order) {
          orderNumber = order.orderNumber;
        }
      }
      return orderNumber;
    },
    barcodeNumber() {
      let barcodeNumber = "";
      if (!this.analisisResults || !this.analisisResults.length) {
        return barcodeNumber;
      }

      if (sessionStorage.getItem("analisis_result")) {
        const barcode = JSON.parse(sessionStorage.getItem("user_form"));
        if (barcode) {
          barcodeNumber = barcode.barcode;
        }
      }
      return barcodeNumber;
    }
  },
  methods: {
    ...mapActions({
      SET_ANALISIS: "ANALISIS_RESULTS_MODULE/SET_ANALISIS",
      REQUEST_ANALISIS_RESULTS: "ANALISIS_RESULTS_MODULE/REQUEST_ANALISIS_RESULTS",
      REQUEST_ANALISIS_VERIFICATION_RESULTS: "ANALISIS_RESULTS_MODULE/REQUEST_ANALISIS_VERIFICATION_RESULTS",
      RESET_ERROR: "ANALISIS_RESULTS_MODULE/RESET_ERROR",
      CANCEL_ANALISIS_RESULTS_REQUESTING: "ANALISIS_RESULTS_MODULE/CANCEL_ANALISIS_RESULTS_REQUESTING",
      RESET_ANALISIS_RESULTS: "ANALISIS_RESULTS_MODULE/RESET_ANALISIS_RESULTS"
    }),
    getWindowWidth() {
      this.windowWidth = document.documentElement.clientWidth;
    },
    async updateResultEvent() {
      this.localRequest = JSON.parse(sessionStorage.getItem("user_form"));
      console.log(this.localRequest);
      this.$store.dispatch("GET_TOKEN").then(() => {
        this.REQUEST_ANALISIS_RESULTS(this.localRequest);
      });
    },
    clearResultEvent() {
      sessionStorage.clear();
      this.RESET_ANALISIS_RESULTS();
      this.localRequest = null;
      this.localAnalisisResult = null;
    },
    switchLocale() {
      switch (this.locale) {
        case "ru":
          this.locale = "en";
          break;
        case "en":
          this.locale = "ru";
          break;
        default:
          this.locale = "ru";
      }
    },
    getClass(min, max, res, normStr, normRes) {
      if (normRes.match(/(\d{1,20})/)) {
        if (parseFloat(res) > parseFloat(max) || parseFloat(res) < parseFloat(min)) {
          this.class = "outNorma";
          return this.class;
        }
      } else {
        if (normRes !== normStr) {
          this.class = "outNorma";
          return this.class;
        }
      }
    },
    canBeTranslated(name) {
      return typeof name === "string" && name.indexOf("SARS-CoV") >= 0;
    },
    translateCovidTestName(name) {
      let res = name;
      if (typeof name === "string" && this.locale === "en") {
        res = res.replace(/ПЦР/g, "PCR");
      }
      return res;
    },
    translateDateTest(dateStr) {
      let res = dateStr;
      if (typeof dateStr === "string" && this.locale === "en") {
        let splitted = dateStr.split(".");
        if (splitted[2]) {
          let splitted2 = splitted[2].split(" ");
          if (splitted2[0]) {
            const year = splitted2[0];
            res = `${year}-${splitted[1]}-${splitted[0]} ${splitted2[1]}`;
          }
        }
      }
      return res;
    },
    translateResult(result) {
      let res = result;
      if (typeof result === "string" && this.locale === "en") {
        res = res.replace(/не обнаружена/gi, "not detected");
        res = res.replace(/положительно/gi, "positive");
      }
      return res;
    }
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.getWindowWidth);
  }
};
</script>

<style scoped lang="scss" src="./TheVerifyResultTable.scss"></style>
