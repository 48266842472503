export default function debounce(fn, delay = 3000) {
    let timeoutID = null;

    return function() {
        clearTimeout(timeoutID);
        timeoutID = setTimeout(() => {
            fn.apply(this, arguments);
        }, delay);
    };
}
