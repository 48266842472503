<template>
  <div id="app">
    <vue-app-view />
  </div>
</template>

<script>
import AppView from "@/views/AppView/AppView";
// import { getToken } from '@/services/sessionService.js'

export default {
  name: "App",
  components: {
    "vue-app-view": AppView
  },
  created() {
    this.$store.dispatch("GET_TOKEN");
    window.addEventListener("keypress", this.keyPress);
  },
  beforeDestroy() {
    window.removeEventListener("keypress", this.keyPress);
  },
  methods: {
    keyPress() {
      window.removeEventListener("keypress", this.keyPress);
      document.getElementById("phoneNumberComponent").focus();
    }
  }
};
</script>

<style lang="scss" src="./App.scss"></style>
