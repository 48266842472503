const MESSAGES = {
    CLEAR_CACHES: "CLEAR_CACHES",
    ONLINE_STATUS_CHANGED__ONLINE: "ONLINE_STATUS_CHANGED__ONLINE",
    ONLINE_STATUS_CHANGED__OFFLINE: "ONLINE_STATUS_CHANGED__OFFLINE"
};

export default class ServiceWorkerController {
    constructor(pathToServiceWorker) {
        this.pathToServiceWorker = pathToServiceWorker;
    }

    async registerServiceWorker() {
        return await navigator.serviceWorker.register(this.pathToServiceWorker);
    }

    async unregisterServiceWorker() {
        const registrations = await navigator.serviceWorker.getRegistrations();

        for (let registration of registrations) {
            await registration.unregister();
        }
    }

    async registerBackgroundSync(backgroundSyncTag) {
        const serviceWorkerRegistration = await navigator.serviceWorker.ready;

        if (serviceWorkerRegistration.sync) {
            return await serviceWorkerRegistration.sync.register(backgroundSyncTag);
        }
    }

    async registerNetworkStatusEvents() {
        window.addEventListener("online", () => {
            this.postMessage(MESSAGES.ONLINE_STATUS_CHANGED__ONLINE);
        });

        window.addEventListener("offline", () => {
            this.postMessage(MESSAGES.ONLINE_STATUS_CHANGED__OFFLINE);
        });
    }

    async postMessage(message) {
        const serviceWorkerRegistration = await navigator.serviceWorker.ready;

        serviceWorkerRegistration.active.postMessage(message);
    }
}
