import { getAnalisisReults, getAnalisisVerificationResults } from "@/services/analisisResultsService";
import router from "../../router/index";
// import jsonTestResult from '../../../testResult'

const ERRORS = {
    UNDEFINED: {
        title: "Ошибка",
        message: "Во время обработки Вашего запроса произошла ошибка. Пожалуйста, повторите попытку позже."
    },
    OFFLINE: {
        title: "Наш ответ",
        message:
            "Приложение не может получить доступ к сети. Обработка Вашего запроса продолжится после переподключения."
    }
};

export default {
    namespaced: true,
    state: {
        error: null,
        analisisResults: null,
        analisisVerificationResults: null,
        analisisResultsRequesting: null,
        requestsTimeout: null
    },
    actions: {
        async REQUEST_ANALISIS_VERIFICATION_RESULTS({ state, dispatch, commit, rootGetters }, data) {
            try {
                if (state.requestsTimeout) {
                    return;
                }

                const gRecaptchaResponse = await window.grecaptcha.execute(window.G_RECAPTCHA_CLIENT_ID, {
                    action: "testResult"
                });
                const apiRequest = getAnalisisVerificationResults();

                data.gRecaptchaResponse = gRecaptchaResponse;

                commit("SET_ANALISIS_RESULTS_REQUESTING", apiRequest);

                const response = await apiRequest.execute(data);
                const analisisVerificationResults =
                    response.data &&
                    response.data.data &&
                    response.data.data.length &&
                    response.data.data[0] &&
                    response.data.data[0].Orders &&
                    response.data.data[0].Orders.length
                        ? response.data.data[0].Orders
                        : null;

                if (analisisVerificationResults) {
                    commit("SET_ANALISIS_VERIFICATION_RESULTS", analisisVerificationResults);

                    if (router.currentRoute.name !== "CheckCodeView") {
                        router.push({ name: "CheckCodeView" });
                    }
                }

                dispatch("RESET_ANALISIS_RESULTS_REQUESTING");
            } catch (error) {
                console.log && console.log(error);
                dispatch("RESET_ANALISIS_RESULTS_REQUESTING");
                if (!rootGetters["ONLINE"]) {
                    dispatch("SET_ERROR", ERRORS.OFFLINE);
                } else if (error.message === "CANCELED_BY_USER") {
                    console.info("Canceled by user");
                } else if (error.response && error.response.data && error.response.data.error) {
                    dispatch("SET_ERROR", {
                        title: "Наш ответ",
                        message: error.response.data.error
                    });

                    if (error.response.data.data[0] && error.response.data.data[0].amountOfTime) {
                        commit("SET_REQUESTS_TIMEOUT", error.response.data.data[0].amountOfTime);
                    }
                } else {
                    dispatch("SET_ERROR", ERRORS.UNDEFINED);
                }
            }
        },
        async REQUEST_ANALISIS_RESULTS({ state, dispatch, commit, rootGetters }, data) {
            try {
                if (state.requestsTimeout) {
                    return;
                }

                const gRecaptchaResponse = await window.grecaptcha.execute(window.G_RECAPTCHA_CLIENT_ID, {
                    action: "testResult"
                });
                const getAnalisisReultsInstance = getAnalisisReults();

                data.gRecaptchaResponse = gRecaptchaResponse;

                commit("SET_ANALISIS_RESULTS_REQUESTING", getAnalisisReultsInstance);

                const response = await getAnalisisReultsInstance.execute(data);
                const analisisResults =
                    response.data &&
                    response.data.data &&
                    response.data.data.length &&
                    response.data.data[0] &&
                    response.data.data[0].Orders &&
                    response.data.data[0].Orders.length
                        ? response.data.data[0].Orders
                        : null;

                if (analisisResults) {
                    commit("SET_ANALISIS_RESULTS", analisisResults);

                    let jsonResult = JSON.stringify(analisisResults);
                    sessionStorage.setItem("analisis_result", jsonResult);

                    if (router.currentRoute.name !== "ResultViewRoute") {
                        router.push({ name: "ResultViewRoute" });
                    }
                }

                dispatch("RESET_ANALISIS_RESULTS_REQUESTING");
            } catch (error) {
                dispatch("RESET_ANALISIS_RESULTS_REQUESTING");

                // let jsonResult = JSON.stringify(jsonTestResult)
                // sessionStorage.setItem('analisis_result', jsonResult);

                if (!rootGetters["ONLINE"]) {
                    dispatch("STORE_ANALISIS_RESULTS_REQUEST", data);
                    dispatch("SET_ERROR", ERRORS.OFFLINE);
                } else if (error.message === "CANCELED_BY_USER") {
                    console.info("Canceled by user");
                } else if (error.response && error.response.data && error.response.data.error) {
                    dispatch("SET_ERROR", {
                        title: "Наш ответ",
                        message: error.response.data.error
                    });

                    if (error.response.data.data[0] && error.response.data.data[0].amountOfTime) {
                        commit("SET_REQUESTS_TIMEOUT", error.response.data.data[0].amountOfTime);
                    }
                } else {
                    dispatch("SET_ERROR", ERRORS.UNDEFINED);
                }
            }
        },
        async REQUEST_STORED_ANALISIS_RESULTS() {
            const serviceWorkerRegistration = await navigator.serviceWorker.ready;

            serviceWorkerRegistration.active.postMessage("LOAD_SAVED_ANALISIS_RESULTS");
        },
        async STORE_ANALISIS_RESULTS_REQUEST(methods, data) {
            const serviceWorkerRegistration = await navigator.serviceWorker.ready;

            serviceWorkerRegistration.active.postMessage({
                message: "STORE_DEFFERED_REQUEST",
                data
            });
        },
        SET_ANALISIS({ commit }, analisisResults) {
            commit("SET_ANALISIS_RESULTS", analisisResults);

            if (router.currentRoute.name !== "ResultViewRoute") {
                router.push({ name: "ResultViewRoute" });
            }
        },
        RESTORE_ANALISIS_RESULTS({ commit }, analisisResults) {
            commit("SET_ANALISIS_RESULTS", analisisResults);
        },

        SET_ERROR({ commit }, error) {
            commit("SET_ERROR", error);
        },
        RESET_ERROR({ commit }) {
            commit("SET_ERROR", null);
        },
        SET_ANALISIS_RESULTS({ commit }, analisisResults) {
            commit("SET_ANALISIS_RESULTS", analisisResults);
        },
        SET_ANALISIS_VERIFICATION_RESULTS({ commit }, analisisVerificationResults) {
            commit("SET_ANALISIS_VERIFICATION_RESULTS", analisisVerificationResults);
        },
        RESET_ANALISIS_RESULTS({ commit }) {
            commit("SET_ANALISIS_RESULTS", null);
        },
        SET_ANALISIS_RESULTS_REQUESTING({ commit }, instance) {
            commit("SET_ANALISIS_RESULTS_REQUESTING", instance);
        },
        CANCEL_ANALISIS_RESULTS_REQUESTING({ dispatch, getters }) {
            getters["ANALISIS_RESULTS_REQUESTING"].cancelTokenSource.cancel("CANCELED_BY_USER");

            dispatch("RESET_ANALISIS_RESULTS_REQUESTING");
        },
        RESET_ANALISIS_RESULTS_REQUESTING({ commit }) {
            commit("SET_ANALISIS_RESULTS_REQUESTING", null);
        }
    },
    mutations: {
        SET_ERROR(state, error) {
            state.error = error;
        },
        SET_ANALISIS_RESULTS(state, analisisResults) {
            state.analisisResults = analisisResults;
        },
        SET_ANALISIS_VERIFICATION_RESULTS(state, analisisVerificationResults) {
            state.analisisVerificationResults = analisisVerificationResults;
        },
        SET_ANALISIS_RESULTS_REQUESTING(state, analisisResultsRequesting) {
            state.analisisResultsRequesting = analisisResultsRequesting;
        },
        SET_REQUESTS_TIMEOUT(state, requestsTimeout) {
            state.requestsTimeout = requestsTimeout;
        }
    },
    getters: {
        ERROR(state) {
            return state.error;
        },
        ANALISIS_RESULTS(state) {
            return state.analisisResults;
        },
        ANALISIS_VERIFICATION_RESULTS(state) {
            return state.analisisVerificationResults;
        },
        ANALISIS_RESULTS_REQUESTING(state) {
            return state.analisisResultsRequesting;
        },
        REQUEST_TIMEOUT(state) {
            return state.requestsTimeout;
        }
    }
};
